@import "../../../../src/common.scss";

.task {

    // need to romove once fix 
    .active {
        width: 100%;
        height: auto;
    }

    .loader td {
        border: none;
        background: none;
    }

    .nav-btn {
        background-color: #ffffff;
        color: $brand-color;
        border: 1px solid $brand-color;
        width: fit-content;

        &:hover {
            background-color: $brand-color;
            color: #ffffff;

        }
    }

    .nav-btn.active {
        background-color: $brand-color;
        border: 1px solid $brand-color;
        width: fit-content;
    }


    .search-bar {
        input {
            border: 1px solid $brand-color;
        }

        .search {
            font-size: 16px;
            max-width: 150px;
            color: $brand-color;

            &::placeholder {
                color: $brand-color;
                font-size: 16px;
            }
        }

        .icon {
            font-size: 24px;
            color: $brand-color;
        }
    }

}