@import "../../common.scss";

.profile {
  &-image {
    & .edit-icon {
      background-color: $brand-color;
      width: 30px;
      height: 30px;
      line-height: 26px;
      bottom: 11px;
      right: 11px;
    }
  }
  
   .input-f {
      display: flex;
      background: rgba(53, 167, 255, 0.05);
      // border: 1px solid #fff;
      border-radius: 20px;
      padding: 10px 20px;
      align-items: center;
     border: 1px solid $brand-color;
    
    }
    .profile-form-control {
      background: transparent;
      border: none;
      color: #000;
      font-size: 20px;
      font-weight: 400;
      &:focus {
        background-color: transparent;
        box-shadow: none;
      }
    }
  
  .custom-btn {
    padding: 5px 24px;
  }
  .modal-dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
