.dashboard {
  .page-tiitle {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: #0059d2;
  }
  .overview {
    background: #ffffff;
    border-radius: 20px;
    min-height: 80vh;
    .overview-section-title {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      color: #0059d2;
    }
    .todays-meeting,
    .todays-tasks {
      h2 {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #242b43;
      }
    }
    .overview-item {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      background: rgba(53, 167, 255, 0.05);
      border: 1px solid #35a7ff;
      border-radius: 20px;
      margin-bottom: 14px;
      .item__title {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #242b43;
        margin: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 30px;
      }
      .item__label {
        width: 85px;
        height: 25px;
        text-align: center;
        background: #35a7ff;
        border-radius: 8px;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        cursor: pointer;
      }
    }
    .announcement {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px 20px;
      background: #35a7ff;
      color: #fff;
      border-radius: 20px;
      .announcement-title {
        display: flex;
        align-items: center;
      }
      .see-more-button {
        font-size: 12px;
        line-height: 22px;
        font-weight: 500;
        text-align: center;
        cursor: pointer;
        width: 100px;
        height: 25px;
        border: 1px solid #ffffff;
        border-radius: 8px;
        color: #fff;
        text-decoration: none;
      }
    }
    .praise {
      background: #0059d2;
    }
  }
}
