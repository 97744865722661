.plan-body-right {
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 50px;
}
.plan-image {
  width: 30px;
  float: left;
  margin-right: 10px;
}
.plan-title-header {
  text-align: center;
  font-family: Roboto;
  float: left;
  margin-top: 5px;
}
.future-date-plan-left {
  font-family: Roboto;
  padding-left: 15px;
  margin-top: 10px;
}
.text-margin-plan {
  padding-right: 5px !important;
  width: 110%;
  border-left: none;
  border-right: none;
  border-top: none;
  margin-bottom: 10px;
}
.plan-add-button-left {
  color: #1e90ff;
  font-family: Roboto;
}

.text-margin-plan-left2 {
  width: 95%;
  border-left: none;
  float: left !important;
  border-right: none;
  border-top: none;
  margin-bottom: 10px;
  margin-left: 8px;
  margin-right: 6px;
  margin-top: 2px;
}
.plan-add-button-left {
  color: #1e90ff;
  font-family: Roboto;
}
.plan-body-right {
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 50px;
}

.plan-title-header {
  text-align: center;
  font-family: Roboto;
  float: left;
  margin-top: 5px;
}

.plan-img {
  width: 33px;
  float: left;
  margin-right: 10px;
}
.plan-image {
  width: 30px;
  float: left;
  margin-right: 10px;
}

.plan-img-issue {
  width: 33px;
  float: left;
  margin-right: 10px;
}

.plan-top {
  text-align: center;
  color: #686868;
  cursor: pointer;
  font-family: Poppins;
}




.plan-header {
  border-bottom: 1px solid #b2b2b2;
  margin-right: 1px;
}
.plan-objective {
  float: left;
  font-family: Roboto;
  margin-top: 12px;
}
.future-date-plan {
  font-family: Roboto;
  margin-top: 10px;
}
.text-margin-plan {
  padding-right: 5px !important;
  width: 110%;
  border-left: none;
  border-right: none;
  border-top: none;
  margin-bottom: 10px;
}
.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.plan-add-button {
  color: #1e90ff;
  font-family: Roboto;
}
.plan-add-button-left {
  color: #1e90ff;
  font-family: Roboto;
}

.plan-add-button:hover {
  color: #1e90ff;
  font-family: Roboto;
}
.plan-title-header {
  text-align: center;
  font-family: Roboto;
  float: left;
  margin-top: 5px;
}

.future-date-plan-left {
  font-family: Roboto;
  padding-left: 15px;
  margin-top: 10px;
}
.text-margin-plan-left {
  padding-right: 5px !important;
  padding-left: 10px !important;
  width: 90%;
  border-left: none;
  border-right: none;
  border-top: none;
  margin-bottom: 10px;
}

.text-margin-plan-left2 {
  width: 95%;
  border-left: none;
  float: left !important;
  border-right: none;
  border-top: none;
  margin-bottom: 10px;
  margin-left: 8px;
  margin-right: 6px;
  margin-top: 2px;
}
.checkbox-plan-left {
  margin-left: 15px;
  display: flex;
}
.plan-check {
  display: block;
  height: 22px;
  width: 22px;
}
.plan-check:checked::after {
  background-color: #9e9e9e;
  color: #fff;
  content: " \221A";
  padding: 2px 6px;
  border-radius: 2px;
}
.plan-check:checked + .text-margin-plan-left2 {
  color: #9e9e9e;
  text-decoration: line-through;
}

.plan-body-right {
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 50px;
}

.description-plan {
  margin-left: 0px;
}
.title-description-plan {
  font-family: Roboto;
}