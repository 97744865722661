@import "../../../../common";

/* global styles for orgchart */
.orgchart-container {
  box-sizing: border-box;
  position: relative;
  margin: 10px;
  height: 777px;
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
  text-align: center;

  &::-webkit-scrollbar {
    width: 3px;
    &-track {
      background: $brand-bg;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    &-thumb {
      background: $brand-color;
    }
  }

  .mode-selection {
    bottom: 50px;
    right: 99px;
    z-index: 9;

    & > div {
      width: 35px;
      height: 35px;
      border-radius: 12px;
      background: white;
      line-height: 30px;
      color: $brand-color;
      border: 1px solid $brand-color;
      font-size: 14px;

      &.active {
        background: $brand-color;
        color: white;
      }
    }
  }

  .orgchart {
    box-sizing: border-box;
    display: inline-block;
    background-size: 10px 10px;
    border: 1px dashed rgba(0, 0, 0, 0);
    padding: 20px 20px 0 20px;
    cursor: all-scroll !important;

    &:not(.react-draggable-dragging) {
      -webkit-transition: -webkit-transform 0.5s ease-out; /* Safari */
      transition: transform 0.5s ease-out;
    }
  }

  & .hidden {
    display: none !important;
  }

  & > .oc-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.3);

    .spinner {
      position: absolute;
      top: calc(50% - 50px);
      left: calc(50% - 50px);

      &::before {
        width: 100px;
        height: 100px;
        border-width: 10px;
        border-radius: 50px;
        border-top-color: rgba(68, 157, 68, 0.8);
        border-bottom-color: rgba(68, 157, 68, 0.8);
        border-left-color: rgba(68, 157, 68, 0.8);
      }      
    }
  }

}