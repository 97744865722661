@import "../../../common.scss";

.hrtag {
  height: 2px;
  border-width: 0;
  color: gray;
  background-color: gray;
  margin-left: 20px;
}

.textdec {
  text-decoration: none !important;
  color: black;
}

.checkout {
  margin-left: 233px;
}

.select-group {
  width: 6rem;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-md-2 {
    flex: 0 0 0;
  }
}

.search {
  margin-top: 10px;
  width: 300px;
  height: 40px;
  font-size: 20px;
  padding: 10px;
  float: right;
}

.employee-listing {
  float: left;
}

.top {
  margin-top: 100px;
}

.main-border {
  margin-top: 30px;
  margin-bottom: 20px;
}

.page-listing select {
  font-size: 16px;
  width: 120px;
  color: #35a7ff;
  border: 1px solid #35a7ff;
  padding: 8px;
  border-radius: 12px;
  outline-color: #35a7ff;
  width: 130px;
}

.custom-icon-style {
  stroke-width: 1 !important;
}

.employee {

  .add-btn{
    font-size: 20px;
    line-height: 25px;
    padding: 13px 20px !important;
    font-weight: 700;
  }
    
  .active-inactive-button{
    background-color: #0D6EFD;
    color: #fff;
  }

  .search-bar {
    input {
      border: 1px solid $brand-color;
    }

    .search {
      font-size: 16px;
      width: 120px;
      color: $brand-color;

      &::placeholder {
        color: $brand-color;
        font-size: 16px;
      }
    }

    .icon {
      font-size: 24px;
      color: $brand-color;
    }
  }

  &_list {
    &-table {}
  }

  #tableExample {
    overflow-x: auto;
  }
}

.import-btn {
  padding:  0 20px;
}

.react-csv-input {
  margin: 0 auto;
  &:hover {
    #react-csv-reader-input::before {
      color: white !important;
    }
  }
}

.custom-modal {
  display: flex !important;
  align-items: center !important;
  height: 100% !important;
  .modal-dialog {
    position: absolute;
    top: unset;
    left: 43%;
    right: unset;
    border-radius: 20px !important;
  }
}

#react-csv-reader-input {
  width: 160px;
  height: 159px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;

  &::-webkit-file-upload-button {
    visibility: hidden;
  }

  &:before {
    content: "+";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    font-size: 65px;
    font-weight: 200;
    color: #35A7FF;
  }
}

.upload-file {
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  font-family: 'Outfit', 'Poppins',  sans-serif;
  color: #35A7FF;
  padding-top: 12px;
}

.bottom-text {
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  font-family: 'Outfit', 'Poppins',  sans-serif;
  color: #242B43;
  padding-bottom: 14px;
}


@media only screen and (max-width: 767px){
  .employee {

    .add-btn{
      text-align: center;
      width: 100%;
      margin: 10px auto 0 auto;
      margin-left: 0 !important;
    }
  }  
}

@media only screen and (min-width: 576px) and (max-width: 1095px){
  .employee {
    .add-btn{
      font-size: 13px;
      line-height: 20px;
      padding: 10px 15px !important;
    }
  }  
}

@media only screen and (min-width: 768px) and (max-width: 1095px){
  .employee {
    .section-header {
      width: 25%;
    }
  }
}
