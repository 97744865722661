@import "../../../common.scss";
.hradd {
  height: 2px;
  border-width: 0;
  color: gray;
  background-color: gray;
}

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey;
  text-align: center;
  padding: 0;
  padding-top: 30px;
}

#progressbar .active {
  color: $brand-color;
  background-color: unset;
}

#progressbar li {
  list-style-type: none;
  font-size: 15px;
  width: 16.6667%;
  float: left;
  position: relative;
  font-weight: 400;
}

#progressbar #step1:before {
  content: "1";
}

#progressbar #step2:before {
  content: "2";
}

#progressbar #step3:before {
  content: "3";
}

#progressbar #step4:before {
  content: "4";
}

#progressbar #step5:before {
  content: "5";
}

#progressbar #step6:before {
  content: "6";
}

#progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0;
  padding: 2px;
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: $brand-color;
}

.progress {
  height: 20px;
}

.progress-bar {
  background-color: $brand-color;
}

#progressbar li:before {
  background: linear-gradient(135deg, rgba(53, 167, 255, 0.2) 0%, rgba(0, 89, 210, 0.2) 100%);
  color: #35A7FF;
  font-weight: bold;
}

#progressbar li strong {
  color: rgba(53, 167, 255, 0.5);
}

#progressbar li.active strong {
  color: #35A7FF;
}

#progressbar li.active:before {
  color: #fff;
  background: linear-gradient(135deg, #35A7FF 0%, #0059D2 100%);
  border: 2px solid #35A7FF;
}

// .fileUpload {
//   position: relative;
//   overflow: hidden;
// }
// .fileUpload input.upload {
//   position: absolute;
//   top: 0;
//   right: 0;
//   margin: 0;
//   padding: 0;
//   font-size: 20px;
//   cursor: pointer;
//   opacity: 0;
//   filter: alpha(opacity=0);
// }

// .btn--browse {
//   border: 1px solid gray;
//   border-left: 0;
//   border-radius: 0 2px 2px 0;
//   background-color: #ccc;
//   color: black;
//   height: 42px;
//   padding: 10px 14px;
// }

// .f-input {
//   height: 42px;
//   background-color: white;
//   border: 1px solid gray;
//   width: 100%;
//   max-width: 400px;
//   float: left;
//   padding: 0 14px;
// }

.employee-add-form {
  .fileUpload {
    height: 32px;
    width: 150px;
    text-align: center;
    right: 20px;
    label{
     color: white;
     background-color: $brand-color;
     margin: 0;
     line-height: 32px;
    }
 }
  & .btn,
  & .btn-primary {
    background-color: $brand-color;
    border-color: $brand-color;
    &:disabled {
      background-color: lightgray;
      border: 0;
      color: white;
      opacity: 1;
    }
  }
}