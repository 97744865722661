$brand-color: #35a7ff;
$brand-color-light: rgba(53, 167, 255, 0.5);
$brand-bg: #f7fbff;
$brand-dark-text: rgba(0, 89, 210, 1);
$text-color-dark: rgba(36, 43, 67, 1);
$brand-upload: rgba(53, 167, 255, 0.2);
$brand-light-bg: rgba(53, 167, 255, 0.05);
$liner-gradient: linear-gradient(135deg, #35a7ff 0%, #0059d2 100%);
$text-white: rgba(255, 255, 255, 0.8);
$text-color-dark: rgba(36, 43, 67, 1);
$brand-upload: rgba(53, 167, 255, 0.2);

.custom-btn {
  background: #f7fbff;
  color: #35a7ff;
  display: inline-flex;
  align-items: center;
  border: 1px solid #35a7ff;
  border-radius: 8px;

  &:hover {
    background: #35a7ff;
    color: #fff;
    border: 1px solid #35a7ff;
  }

  svg {
    margin-right: 6px;
  }
}

.admin-list-table {
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 2px 10px 20px #ccc;
  min-width: 121%;
}

.delete-ico {
  color: #dc3545;

  &:hover {
    color: red;
    cursor: pointer;
  }
}

.edit-ico {
  color: #35a7ff;

  &:hover {
    color: #92d0ff;
    cursor: pointer;
  }
}

.reply-ico {
  color: #35a7ff;

  &:hover {
    color: #92d0ff;
    cursor: pointer;
  }
}

.view-ico {
  color: #35a7ff;

  &:hover {
    color: #92d0ff;
    cursor: pointer;
  }
}

.add-icon {
  background: #35a7ff;
  border: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  line-height: 12px;
  color: #ffffff;
  cursor: pointer;
}

.section-header {
  color: $brand-dark-text;
  font-size: 24px;
  line-height: 25px;
  font-weight: 600;
}

.sub-header {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
}

.form-control {
  border-radius: 12px;
  border: 1px solid $brand-color;

  &:focus {
    background-color: #fff;
    border-color: none;
    outline: 0;
    box-shadow: none;
  }

  &::placeholder {
    color: $text-color-dark;
    font-size: 16px;
    line-height: 25px;
  }

  &.h-70 {
    height: 70px;
  }
}

.custom-btn {
  padding: 0px 10px;
  border-radius: 12px;
  background: none;
  color: $brand-color;

  &:hover {
    background: $brand-color;
    color: #fff;
  }

  &:focus {
    box-shadow: none;
    outline: none;
    color: #fff;
    background-color: $brand-color;
    border: 0;
  }
}

.custom-btn-fill {
  padding: 0px 10px;
  border-radius: 12px;
  background: #35A7FF;
  color: #fff;

  &:hover {
    background: $brand-color;
    color: #fff;
  }

  &:focus {
    box-shadow: none;
    outline: none;
    color: #fff;
    background-color: $brand-color;
    border: 0;
  }
}

.modal-backdrop.show {
  opacity: 0;
}

// ===Common Styles
// ====================

// title style
.rt-title {
  color: #0059d2;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
}

// buttons style

.btn-rt-primary {
  background: #35a7ff;
  border-radius: 15px;
  border-color: #35a7ff;
  line-height: 30px;
  padding-left: 20px;
  padding-right: 20px;

  &:hover {
    background: #fff !important;
    color: #35a7ff !important;
    border-color: #35a7ff !important;
  }
}

.btn-rt-primary-icon {
  background: #35a7ff;
  border-radius: 15px;
  border-color: #35a7ff;
  line-height: 30px;
  padding-left: 20px;
  padding-right: 20px;

  svg {
    height: 20px;
    margin-right: 8px;
    margin-top: -4px;
  }

  &:hover {
    background: #fff !important;
    color: #35a7ff !important;
    border-color: #35a7ff !important;
  }
}

.rt-spinner {
  color: #35a7ff;
}

.rt-spinner {
  color: #35a7ff;
}

.chart-add-btn {
  border-color: $brand-color !important;
  background-color: $brand-bg !important;
  color: $brand-color-light !important;
  border-style: dotted !important;
  outline: 0;

  &:focus,
  &:active,
  &:active:focus {
    outline: 0;
    box-shadow: none;
  }
}

.delete-button {
  border: 0;
  color: #35a7ff;
  background-color: transparent;
  font-size: 25px;
  padding: 0;
  height: auto;
  text-align: right;

  &:hover {
    color: #dc3545;
  }
}

.add-btn {
  background-color: $brand-color;
  padding: 10px 15px;
  border-radius: 12px;
}

.rounded-border-12 {
  border-radius: 12px;
}

.min-vh-80 {
  min-height: 80vh;
}

.click-add-btn {
  width: 120px;
  background: $brand-light-bg;
  color: $brand-color;
  padding: 10px;
  border-style: dashed;

  &:hover {
    background-color: $brand-light-bg;
    color: $brand-color;
  }
}

.table {
  border-spacing: 0 10px;
  border-collapse: separate;

  th {
    font-weight: 400;
    color: $text-color-dark;
    font-size: 14px;
    line-height: 24px;
  }

  td {
    height: 70px;
    vertical-align: middle;
    background: $brand-light-bg;
    border: 1px solid $brand-color;

    &:first-child {
      border-right: 0;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    &:last-child {
      border-left: 0;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
}

.text-xs {
  font-size: 12px;
  line-height: 24px;
}

.text-md {
  font-size: 18px;
  line-height: 25px;
}

.light-bg {
  background: $brand-color-light;
}

.small-text {
  color: rgba(36, 43, 67, 0.5);
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

.text-bold {
  font-weight: 600;
  color: #333;
}

input#card-email:-internal-autofill-selected {
  background-color: transparent !important;
  background-image: none !important;
  color: rgb(0, 0, 0) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: rgb(0, 0, 0) !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.small-icon {
  width: 40px;
  height: 40px;
}
.small-icon-radius {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.section-header {
  color: $brand-dark-text;
  font-size: 24px;
  line-height: 25px;
  font-weight: 600;
}

.rounded-border-12 {
  border-radius: 12px;
}

.pt-80 {
  padding-top: 80px;
}

// .fade.modal-backdrop.show {
//   opacity: 0 !important;
// }

.profile-upload-icon {
  height: 160px;
  width: 160px;
  font-size: 35px;
  background-color: $brand-upload;
  color: $brand-color;

  &:hover {
    background-color: $brand-color-light;
  }
}

button,
.btn-primary {

  &:focus,
  &:active {
    outline: 0;
    box-shadow: none;
  }
}

.custom-modal {
  & .modal-title {
    font-size: 20px;
    line-height: 25px;
    color: $text-color-dark;
  }

  &.show {
    & .modal-dialog {
      // position: absolute;
      // top: 17%;
      // left: 38%;
      width: 100%;
      box-shadow: $brand-color 0px 0px 10px;
      border-radius: 8px;

      & .modal-content {
        background-color: transparent;
        backdrop-filter: blur(8px);
      }
    }
  }
}

.badge-btn {
  border-radius: 12px;
  padding: 4px 9px;
}

.bg-brand {
  background-color: $brand-color;
  color: #ffffff;
}

.accordion-button:focus {
  // border-color: none;
  outline: 0;
  box-shadow: none;
}

.ck-editor__editable_inline {
  min-height: 500px;
}

.delete-btn-announcement {
  margin-left: 3%;
}


.fade.modal-backdrop.show {
  background: rgba(0, 89, 210, 0.1) !important;
  backdrop-filter: blur(2.5px) !important;
  opacity: 9 !important;
}
.input-warning-style {
  color: #FF0000;
  margin-left: 5px;
}
.attendees-checkbox input{
  width:20px;
  margin-left: 2%;
  height: 20px;
  padding: 0;
}
.attendees-label{
  margin-left: 6%;
}

.agenda-checkbox input{
  width:20px;
  height: 20px;
  margin-left: 0;
  padding: 0;
}
.agenda-label{
  margin-left: 6%;
}
