.vision-subtitle {
    font-weight: 500;
}

.marketing-stretegy-part {
    border-right: 1px solid #E0E0E0;
    border-bottom-left-radius: 20px;
    padding-top: 90px !important;
}


.marketing-stretegy-icon {
    height: auto;
}



.custom-margin {
    margin-bottom: 100px;
}

.border-container {
    border: 1px solid #ffffff;
    border-radius: 20px;

}

.custom-row {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.custom-border-left {
    border-left: 1px solid #fff;
}

.custom-border-bottom {
    border-bottom: 1px solid #fff;
}

/* .accordion {
    border: 0px;
}
.accordion__button{
    border-radius: 15px;
    background-color: #fff;
    color: #0059d2;
}

.accordion__button:hover {
    background-color: #35a7ff;
    color: #fff;
}

.accordion__item + .accordion__item{
    border-top: 0px;
}
.accordion__item{
    margin-bottom: 20px;
}

.accordion__panel {
    background-color: #fff;
    margin-bottom: 20px;
    border-radius: 0px 0px 15px 15px;
}

.accordion__button[aria-expanded='true'], .accordion__button[aria-selected='true'] {
    border-radius: 15px 15px 0px 0px!important;
} */

.children-container .form-control {
    background-color: rgba(53, 167, 255, 0.05);
}

.modal-footer {
    justify-content: center !important;
}

.accordion .card {
    border-radius: 15px !important;
    margin-bottom: 10px !important;
}

.accordion .card-header {
    background-color: #fff;
    font-family: Outfit;
    color: #0059d2;
    border-radius: 15px !important;
    border-bottom: 0px;
}

.accordion .btn-link:hover {
    text-decoration: none !important;
    color: #fff;
}

.accordion .btn-link:hover,
.accordion .btn-link:focus {
    text-decoration: none !important;
}

.accordion .btn {
    padding: 0px;
}

.accordion .card-header:hover,
.accordion .card-header:hover .btn-link,
.accordion .card-header:focus {
    background-color: #35a7ff;
    color: #fff !important;
}



.module-vison {
    .accordion-item {
        margin-bottom: 10px;
        border-radius: 15px !important;

        .accordion-header {
            border-radius: 15px;

            .accordion-button {
                background: #fff;
                border-radius: 15px;
                font-weight: 500;
            }

            .collapsed {
                border-radius: 15px !important;
            }
        }
    }

    .text-area-field-style {
        border: 1px solid #35A7FF !important;
        min-height: 110px !important;
        border-radius: 8px;

    }

    /* width */
    textarea::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    textarea::-webkit-scrollbar-track {
        background: #b0cffc;
        border-radius: 25px;
    }

    /* Handle */
    textarea::-webkit-scrollbar-thumb {
        background: #35A7FF;
        border-radius: 25px;
    }


}