@import "../../../../common";
.orgchart {
  &.noncollapsable .oc-node .oc-edge {
    display: none;
  }
  ul {
    padding-left: 0;
    text-align: center;
    display: flex;
    margin: 0;
    transition: transform 0.3s, opacity 0.3s;
    li {
      display: inline-block;
      position: relative;
      padding-top: 20px;

      & .oc-node {
        box-sizing: border-box;
        display: inline-block;
        position: relative;
        padding: 3px;
        border: 1px dashed transparent;
        margin-bottom: 20px;
        padding-bottom: 20px;

        &.isChildrenCollapsed:not(:only-child)::after {
          content: none;
        }

        &.allowedDrop {
          border-color: #2799eb;
        }
        &.currentDropTarget {
          background-color: #2799eb;
        }

        & .oc-heading {
          box-sizing: border-box;
          padding: 2px;
          margin: 0;
          width: 200px;
          height: 100px;
          text-align: center;
          font-size: 0.75rem;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          background-color: rgba(217, 83, 79, 0.8);
          color: #fff;
          border-radius: 4px 4px 0 0;

           .oc-symbol {
            float: left;
            margin-top: 12px;
            margin-left: 2px;

            ::before {
              background-color: #fff;
              border-color: rgba(217, 83, 79, 0.8);
            }

            ::after {
              background-color: #fff;
            }
          }
        }
        & .oc-content {
          box-sizing: border-box;
          padding: 2px;
          width: 130px;
          height: 20px;
          font-size: 0.625rem;
          border: 1px solid rgba(217, 83, 79, 0.8);
          border-radius: 0 0 4px 4px;
          text-align: center;
          background-color: #fff;
          color: #333;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        & .oc-edge {
          position: absolute;
          cursor: default;
          transition: 0.2s;
          &::before {
            border-color: #2799eb;
          }
          &:hover {
            cursor: pointer;
            &::before {
              border-color: #2799eb;
            }
          }
        }

        & .verticalEdge {
          width: calc(100% - 6px);
          width: -webkit-calc(100% - 6px);
          width: -moz-calc(100% - 6px);
          height: 10px;
          left: 3px;
          &::before {
            position: absolute;
            left: calc(50% - 0.3125rem);
          }
        }

        & .topEdge {
          top: -2px;
          &.oci-chevron-up::before {
            top: 2px;
          }
          &.oci-chevron-down::before {
            bottom: 3px;
          }
        }

        & .bottomEdge {
          bottom: -2px;
          &.oci-chevron-up::before {
            bottom: -3px;
          }
          &.oci-chevron-down::before {
            bottom: 1px;
          }
        }

        & .horizontalEdge {
          width: 10px;
          height: calc(100% - 6px);
          height: -webkit-calc(100% - 6px);
          height: -moz-calc(100% - 6px);
          top: 3px;
          &::before {
            position: absolute;
            top: calc(50% - 0.3125rem);
          }
        }

        & .rightEdge {
          right: -2px;
          &.oci-chevron-left::before {
            right: -3px;
          }
          &.oci-chevron-right::before {
            right: 1px;
          }
        }

        & .leftEdge {
          left: -2px;
          &.oci-chevron-right::before {
            left: -3px;
          }
          &.oci-chevron-left::before {
            left: 1px;
          }
        }
      }
    }
  }

  & > ul > li > ul {
    li{
      &.isSiblingsCollapsed {
        &::before {
          content: none;
        }
        & > .oc-node::before {
          top: -13px;
          height: 11px;
        }
      }
      &:last-child::before {
        width: calc(50% + 1px);
      }
      &:only-child::before {
        width: 2px;
      }
      &.isAncestorsCollapsed > .oc-node::before {
        content: none;
      }
    }
  }

  & .slide-right,
  & .slide-left,
  & .slide-down,
  & .slide-up {
    opacity: 0;
  }

  & .slide-right {
    transform: translateX(130px);
  }

  & .slide-left {
    transform: translateX(-130px);
  }

  & .slide-up {
    transform: translateY(-40px);
  }

  & .slide-down {
    transform: translateY(40px);
  }
  .oci {
    display: inline-block;
    position: relative;
    font-style: normal;
    font-family: Arial;

    &-chevron-up::before {
      content: "";
      display: inline-block;
      box-sizing: border-box;
      vertical-align: text-bottom;
      width: 0.625rem;
      height: 0.625rem;
      border-width: 0 0.2rem 0.2rem 0;
      border-style: solid;
      border-color: #000;
      background: transparent;
      transform: rotate(-135deg);
    }
    &-chevron-right::before {
      content: "";
      display: inline-block;
      box-sizing: border-box;
      width: 0.625rem;
      height: 0.625rem;
      border-width: 0 0.2rem 0.2rem 0;
      border-style: solid;
      border-color: #000;
      background: transparent;
      transform: rotate(-45deg);
    }
    &-chevron-down::before {
      content: "";
      display: inline-block;
      box-sizing: border-box;
      width: 0.625rem;
      height: 0.625rem;
      border-width: 0 0.2rem 0.2rem 0;
      border-style: solid;
      border-color: #000;
      background: transparent;
      transform: rotate(45deg);
    }
    &-chevron-left::before {
      content: "";
      display: inline-block;
      box-sizing: border-box;
      width: 0.625rem;
      height: 0.625rem;
      border-width: 0 0.2rem 0.2rem 0;
      border-style: solid;
      border-color: #000;
      background: transparent;
      transform: rotate(135deg);
    }
    &-leader {
      &::before {
        position: absolute;
        content: "";
        display: inline-block;
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 0.2rem;
        background: #000;
        top: -0.75rem;
        left: 0.125rem;
      }
      &::after {
        position: absolute;
        content: "";
        display: inline-block;
        width: 0.875rem;
        height: 0.375rem;
        border-radius: 0.25rem 0.25rem 0 0;
        background: #000;
        top: -0.3rem;
        left: -0.125rem;
      }
    }

    &-plus {
      &-square::before {
        content: "+";
        display: inline-block;
        vertical-align: text-bottom;
        text-align: center;
        width: 1rem;
        height: 1rem;
        background-color: #000;
        color: #fff;
      }
      &-circle::before {
        content: "+";
        display: inline-block;
        vertical-align: text-bottom;
        text-align: center;
        width: 1rem;
        height: 1rem;
        border-radius: 0.5rem;
        background-color: #000;
        color: #fff;
      }
    }

    &-minus {
      &-square::before {
        content: "−";
        display: inline-block;
        vertical-align: text-bottom;
        text-align: center;
        width: 1rem;
        height: 1rem;
        background-color: #000;
        color: #fff;
      }
      &-circle::before {
        content: "−";
        display: inline-block;
        vertical-align: text-bottom;
        text-align: center;
        width: 1rem;
        height: 1rem;
        border-radius: 0.5rem;
        background-color: #000;
        color: #fff;
      }
    }

    &-arrow-circle {
      &-up::before {
        content: "▲";
        display: inline-block;
        text-align: center;
        vertical-align: text-bottom;
        width: 1rem;
        height: 1rem;
        border-radius: 0.5rem;
        background-color: #000;
        color: #fff;
        font-size: 0.875rem;
      }

      &-down::before {
        content: "▼";
        text-align: center;
        display: inline-block;
        vertical-align: text-bottom;
        width: 1rem;
        height: 1rem;
        border-radius: 0.5rem;
        background-color: #000;
        color: #fff;
        font-size: 0.875rem;
      }
    }

    &-info-circle::before {
      content: "i";
      display: inline-block;
      vertical-align: text-bottom;
      width: 1rem;
      height: 1rem;
      border-radius: 0.5rem;
      background-color: #000;
      color: #fff;
      text-align: center;
      font-weight: bold;
    }

    &-spinner::before {
      content: "";
      vertical-align: text-bottom;
      display: inline-block;
      box-sizing: border-box;
      width: 1rem;
      height: 1rem;
      border: 0.1rem solid #000;
      border-right-color: transparent;
      border-radius: 0.625rem;
      animation: oci-infinite-spinning 0.75s linear infinite;
    }
  }
  & .tree-add-icon {
    position: absolute;
    bottom: -10px;
    display: inline-block;
    background: #35a7ff;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    line-height: 17px;
    font-size: 15px;
    left: 50%;
    transform: translateX(-50%);
  }

  & ul > li > ul li::before {
    content: "";
    position: absolute;
    top: -11px;
    left: 0;
    width: 100%;
    border-top: 1px solid $brand-color;
    box-sizing: border-box;
  }

  & ul li .oc-node:not(:only-child)::after,
  & > ul > li > ul li > .oc-node::before {
    content: "";
    position: absolute;
    bottom: -11px;
    left: calc(50% - 1px);
    width: 1px;
    height: 22px;
    background-color: $brand-color;
  }

  // /* excluding root node */
  & > ul > li > ul li > .oc-node::before {
    top: -31px;
    bottom: unset;
    height: 35px;
  }

  & > ul > li > ul li:first-child::before {
    left: calc(50% - 1px);
    width: calc(50% + 1px);
    // border-top-left-radius: 16px;
    // height: 15px;
  }

  & li.oc-hierarchy ul li:only-child:before {
    display: none;
  }

  & .tree-card {
    width: 250px;
    height: auto;
    border: 1px solid $brand-color;
    background-color: #f7fbff;
    border-radius: 20px;
    padding: 15px;
    min-height: 210px;
    cursor: default !important;

    & .card-header {
      text-align: left !important;
      color: #242b43;
      font-weight: bold;
      background: transparent;
      border: 0;
      padding-bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 0;
    }
    p.seat-owner {
      margin-left: 10px;
      border-style: none;
      font-size: 14px;
      background-color: transparent;
      font-weight: bold;
      text-transform: capitalize;
    }
    input {
      background: transparent;
      border: 0;
      outline: 0;
      width: 100%;
      font-size: 14px;
    }
    .roleList li {
      display: list-item;
      list-style: outside;
      color: rgba(36, 43, 67, 1);
      margin-left: 18px;
    }

    .roleList:only-child li {
      color: rgba(36, 43, 67, 0.2);
    }
  }
  & .tags {
    li {
      background: rgba(53, 167, 255, 0.2);
      border-radius: 6px;
      padding: 0 5px;
      font-size: 12px;
      color: rgba(53, 167, 255, 1);
      cursor: default;

      &:before {
        display: none;
      }
    }
  }
  & .tag-icon {
    color: $brand-color;
    font-size: 18px;
    &:hover {
      color: rgba(0, 89, 210, 1);
    }
  }
  & .tag-modal {
    position: absolute;
    top: 84%;
    left: 57%;
    z-index: 9;
    width: 280px;
    height: 220px;
    border: 1px solid $brand-color;
    background-color: transparent;
    border-radius: 20px;
    padding: 15px;
    overflow-y: hidden;
    backdrop-filter: blur(10px);
    cursor: default;

    ul li {
      &:before {
        display: none;
      }
    }

    & .tag-content input {
      border: 0;
      outline: 0;
      border-bottom: 1px solid $brand-color;
      color: #333;
      background-color: transparent;

      &::placeholder {
        color: rgba(36, 43, 67, 0.2);
      }
    }

    & .tag-list {
      height: 150px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 3px;
        &-track {
          background: $brand-bg;
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }
        &-thumb {
          background: $brand-color;
        }
      }
    }
  }
}

/* Card design */
@media only screen and (max-width: 600px) {
  .tree-root {
    margin-left: 10rem;
  }
}
