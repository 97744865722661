.cust-dropdown-btn button {
  width: 90px;
  height: 25px;
  background: #0059d2;
  border-radius: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  color: #ffffff;
  text-align: center;
}

.cust-dropdown-btn button::after {
  display: none;
}

.cust-dropdown-btn .dropdown-menu {
  max-height: 200px;
  overflow: auto;
  background: rgba(255, 255, 255, 0.6);
  border: 1.38554px solid #35a7ff;
  box-shadow: 2.77108px 2.77108px 13.8554px rgba(0, 89, 210, 0.3);
  backdrop-filter: blur(5px);
  border-radius: 13.8554px;
  padding: 8px 8px 0;
  transform: translate(0px, 30px) !important;
}

.cust-dropdown-btn .dropdown-menu .dropdown-item {
  width: 200px;
  background: rgba(53, 167, 255, 0.2);
  border-radius: 7px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #35a7ff;
  padding: 8px 0;
  margin-bottom: 8px;
}

.cust-dropdown-btn .dropdown-menu .dropdown-item:hover {
  font-weight: 700;
}

.active-menu-item {
    background-color: #35A7FF !important;
    color: #FFFFFF !important;
}