@import "../../common.scss";
.section-metting {

  .title {
    display: inline-block;
    font-weight: 600;
    font-size: 24px;
    color: #0059D2;
  }


  .card {
    border: none;

    .card-header {
      padding: 25px 35px;
      height: auto;
      color: #0059D2;
      transition: 0.3s;

      button.collapsed {
        font-weight: 400;
      }

      button {

        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
      }

    }

    .card-body {

      a {
        text-decoration: none;
      }

      .title {
        display: inline-block;
        color: #000000;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
      }

      .add-meeting {
        color: #35A7FF;
        font-size: 35px;
        padding-left: 15px;
      }
    }

    .meetingItem {
      background: rgba(53, 167, 255, 0.05);
      border: 1px solid #35A7FF !important;
      border-radius: 20px;
      color: #0a58ca;

      .meeting-name {
        font-size: 20px;
        line-height: 48px;
        padding-left: 30px;
        font-weight: 500;
      }

      .meeting-icons,
      .meeting-date {
        line-height: 50px;
      }

      .rt-denger {
        color: #DC3545;
        cursor: pointer;
      }

      .rt-info {
        color: #35A7FF;
        cursor: pointer;
      }
    }
  }

  // resetting the default css effect
  .btn-link.btn-block.text-left {
    background: transparent;
  }
}
.add-agenda-btn{
  background: rgba(53, 167, 255, 0.05);
  border-radius: 15px;
  padding: 0.5% 1%;
  border: none;
}
// .custom-margin{
//   margin-top: 4rem;
// }

.meeting-agenda-modal {
  p {
    color: rgba(36, 43, 67, 1);
    font-size: 16px;
  }
  .modal-dialog {
    width: 29rem;
    position: absolute;
    right: 847px;
    top: 170px;
    
  }
  .modal-content {
    border-radius: 20px;
    border: 1px solid $brand-color;
    box-shadow: -4px -3px 33px -8px rgba(53, 167, 255, 0.2);
    background-color: transparent;
    backdrop-filter: blur(12px);
  }

  .radio-btn-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    height: 178px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 3px;
      overflow: hidden;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $brand-color;
      outline: 1px solid $brand-color;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: rgba(53, 167, 255, 0.2);
    }

    & .radio-btn {
      input[type="radio"] {
        position: absolute;
        opacity: 0;
        &:checked + .badge-box-items {
          border: 1px solid $brand-color;
        }
      }
    }
    & .badge-box {
      width: 77px;
      border-radius: 12px;
      background-color: rgba(53, 167, 255, 0.2);
      cursor: pointer;
      transition: all 0.3s ease-in;

      & .badge-box-items {
        padding: 10px 5px;
        border-radius: 12px;
        span {
          font-size: 12px;
          display: block;
          padding-top: 5px;
        }

        & .image-badge {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}

.meeting-modal {
  
  p {
    color: rgba(36, 43, 67, 1);
    font-size: 16px;
  }
  .modal-dialog {
    width: 29rem;
    position: absolute;
    right: 186px;
    top: 144px;
    
  }
  .modal-content {
    border-radius: 20px;
    border: 1px solid $brand-color;
    box-shadow: -4px -3px 33px -8px rgba(53, 167, 255, 0.2);
    background-color: transparent;
    backdrop-filter: blur(12px);
  }

  .radio-btn-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    height: 178px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 3px;
      overflow: hidden;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $brand-color;
      outline: 1px solid $brand-color;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: rgba(53, 167, 255, 0.2);
    }

    & .radio-btn {
      input[type="radio"] {
        position: absolute;
        opacity: 0;
        &:checked + .badge-box-items {
          border: 1px solid $brand-color;
        }
      }
    }
    & .badge-box {
      width: 77px;
      border-radius: 12px;
      background-color: rgba(53, 167, 255, 0.2);
      cursor: pointer;
      transition: all 0.3s ease-in;

      & .badge-box-items {
        padding: 10px 5px;
        border-radius: 12px;
        span {
          font-size: 12px;
          display: block;
          padding-top: 5px;
        }

        & .image-badge {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  .searchWrapper {
    border-bottom: 1px solid $brand-color;
    border-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    padding-left: 0;
    & input {
      padding-left: 0;
      &::placeholder {
        color: rgba(36, 43, 67, 1);
      }
    }
  }

  .form-control {
    background-color: transparent;
    &:focus {
      background-color: transparent;
    }
    &::placeholder {
      color: rgba(36, 43, 67, 1);
    }
  }
}


.attendees-img{
  width: 40px;
  height: 40px;
}

  .search-bar {
    input {
      border: 1px solid $brand-color;
    }

    .search {
      font-size: 16px;
      width: 180px;
      color: $brand-color;

      &::placeholder {
        color: $brand-color;
        font-size: 16px;
      }
    }

    .icon {
      font-size: 24px;
      color: $brand-color;
    }
  }

.rt-dw {
  display: flex;

  button:focus{
    outline: none;
}
  .btn.btn-light:nth-child(1) {
   display: none;
  }

  .btn.btn-light:nth-child(2) {
    border-radius: inherit;
    margin: 3px;
  }
}

