@import "../../../../src/common.scss";

.task-modal {

    .br-12 {
        border-radius: 12px;
    }

    .modal-xl {
        min-width: 90vw !important;
    }

    // need to romove once fix 
    .active {
        width: 100%;
    }

    .modal-content {
        border: 1px solid $brand-color !important;
        box-shadow: 2px 2px 10px rgba(0, 89, 210, 0.3) !important;
    }

    .modal-title {
        font-weight: 400;
        font-size: 20px;
    }

    .modal-header {
        border: none !important;
    }


    .p-full {
        padding: 30px 10px;
    }


    .ck.ck-toolbar.ck-toolbar_grouping {
        border-top-left-radius: 15px !important;
        border-top-right-radius: 15px !important;
        border-color: $brand-color;
    }

    .ck.ck-editor__main>.ck-editor__editable {
        border-bottom-left-radius: 15px !important;
        border-bottom-right-radius: 15px !important;
        border-color: $brand-color;
    }

    .nav-btn {
        background-color: #ffffff;
        color: $brand-color;
        border: 1px solid $brand-color;
        width: fit-content;

        &:hover {
            background-color: $brand-color;
            color: #ffffff;

        }
    }

    .nav-btn.active {
        background-color: $brand-color;
        border: 1px solid $brand-color;
        width: fit-content;
    }





    .ck.ck-editor__main {
        height: 500px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 3px;
            overflow: hidden;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $brand-color;
            outline: 1px solid $brand-color;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
            background-color: #fff;
        }
    }




    .task-des {
        height: 565px !important;
        overflow-y: scroll;
        margin-left: 5px;


        &::-webkit-scrollbar {
            width: 3px;
            overflow: hidden;

        }

        &::-webkit-scrollbar-thumb {
            background-color: $brand-color;
            outline: 1px solid $brand-color;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
            background-color: #fff;
        }
    }

    .chat {


        max-height: 730px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 3px;
            overflow: hidden;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $brand-color;
            outline: 1px solid $brand-color;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
            background-color: #fff;
        }

        .task-comment-box textarea {
            max-height: 120px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 3px;
                overflow: hidden;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $brand-color;
                outline: 1px solid $brand-color;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-track {
                background-color: #fff;
            }
        }

        .chat-body {
            background: rgba(53, 167, 255, 0.2);
            border-radius: 10px;
            margin-bottom: 10px;
            margin-right: 5px;

            h6 {
                font-weight: 700;
            }

            .date {
                font-weight: 400;
                font-size: 12px;
                color: rgba(53, 167, 255, 0.8);
            }

            .chat-text {
                font-weight: 400;
                font-size: 14px;
            }

        }

        .reply {
            padding-left: 25px;
        }

        [contenteditable="true"] {
            padding: 15px 5px;
            background-color: #ffffff;
            border: 1px solid #35a7ff;
            border-radius: 12px;
        }
    }



    .assignee {
        width: auto;
        background: rgba(53, 167, 255, 0.2);
        border-radius: 30px;
        border: none;
    }

    .border {
        background: rgba(53, 167, 255, 0.05);
        border: 1px solid $brand-color !important;
        border-radius: 20px;
    }

    .du-dt {
        input[type="date"] {
            -webkit-appearance: listbox;
            border: none;
            background: #f8faff;
        }
    }


}