.rt-add-process-doc {
    box-shadow: 2px 10px 20px #ccc;
    background: #fff;
    border: 1px solid #35a7ff;
    border-radius: 20px;

    input {
        background: rgba(53,167,255,.05);
        border: 1px solid #35a7ff;
        border-radius: 20px;
        outline: none;
        padding: 30px;
    }
    .ck.ck-toolbar.ck-toolbar_grouping {
        border-top-left-radius: 15px !important;
        border-top-right-radius: 15px !important;
        border-color: #35a7ff;
    }

    .ck.ck-editor__main>.ck-editor__editable{
        border-bottom-left-radius: 15px !important;
        border-bottom-right-radius: 15px !important;
        border-color: #35a7ff;
    }
}