.content-container {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px 50px;
}

.column-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #242b43;
  padding-left: 16px;
}

.capsule-wrapper {
  max-height: 60vh;
  overflow: auto;
}

.module-selector button {
  background: transparent !important;
  border: 0;
  outline: none !important;
  box-shadow: none !important;
  width: 40px;
  padding: 0;
}

.module-selector::after {
  display: none;
}