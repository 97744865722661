/* body {
    font-family: Outfit;
} */

.container-bg {
  background: linear-gradient(
    63.18deg,
    rgba(31, 47, 152, 0.1) 0%,
    rgba(28, 167, 236, 0.1) 100%
  );
}
.hello-icon {
  width: 20px;
  height: 20px;
  margin-top: 9px;
  padding-left: 2px;
}

.sidebar-height {
  min-height: 100vh;
  height: auto;
  /* background: linear-gradient(to bottom, #35a7ff7e 5%, #35a7ff94 100%); */
  background: linear-gradient(
    174.76deg,
    rgba(53, 167, 255, 0.05) 0%,
    #35a7ff 100%
  );
  /* background: radial-gradient(
      circle farthest-side at 97% 14%,
      #1f2f98 0%,
      rgba(255, 255, 255, 0) 99%
    ),
    linear-gradient(180deg, rgba(53, 167, 255, 0.05) 1%, #35a7ff 100%),
    linear-gradient(180deg, #fff 0%, #fff 100%);
  background-repeat: no-repeat, repeat, repeat;*/
  border-radius: 0px 15px 15px 0px;
}

.top-area {
  padding-top: 25px;
  padding-bottom: 20px;
}

.bottom-area {
  margin-top: 100px;
}
.sidenav---sidenav---_2tBP {
  background: none !important;
  display: contents;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
  min-width: none !important;
}

.sidenav---navtext---1AE_f {
  line-height: 50px !important;
  color: #0059d2 !important;
  font-weight: 500 !important;
}

.sidenav---navitem---9uL5T:hover,
.sidenav---sidenav-subnavitem---1cD47 .sidenav---navitem---9uL5T:hover {
  background: #0058d23b !important;
  border-radius: 10px;
}

.sidenav---navitem---9uL5T::before {
  border-left: none !important;
}

/* .sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61>.sidenav---sidenav-subnavitem---1cD47>.sidenav---navitem---9uL5T {
    padding: 0 14px 0 68px !important;
} */

.sidenav---sidenav-subnavitem---1cD47::before {
  height: 50px !important;
  z-index: 99999 !important;
  content: "" !important;
  display: block !important;
  width: 0 !important;
  position: absolute !important;
  top: -32px !important;
  bottom: 0 !important;
  left: 28px !important;
  border-left: 1px solid #0059d2 !important;
}

.sidenav---sidenav-subnavitem---1cD47 .sidenav---navitem---9uL5T::before {
  content: "" !important;
  display: block !important;
  width: 14px !important;
  height: 14px !important;
  margin-top: -1px !important;
  position: absolute;
  bottom: 19px !important;
  left: 28px !important;
  z-index: 99999 !important;
  border-left: 1px solid #0059d2 !important;
  border-bottom: 1px solid #0059d2 !important;
  border-radius: 0px 0px 0px 28px !important;
}

.sidenav---sidenav-navitem---uwIJ-.sidenav---selected---1EK3y.sidenav---highlighted---oUx9u.sidenav---expandable---3_dr7.sidenav---expanded---1KdUL
  .sidenav---sidenav-subnavitem---1cD47
  .sidenav---navitem---9uL5T::before {
  bottom: 0 !important;
  top: 18px !important;
  left: 28px !important;
}

.submenu-style.sidenav---selected---1EK3y .sidenav---navitem---9uL5T {
  background: #0058d23b !important;
}

.sidenav---navitem---9uL5T {
  border-radius: 10px;
}

.border-top {
  border-top: 1px solid #f8f9fa;
}

.border-btm {
  border-bottom: 1px solid #f8f9fa;
}

.sidenav---sidenav-nav---3tvij
  .sidenav---sidenav-navitem---uwIJ-
  .sidenav---navitem---9uL5T {
  white-space: normal !important;
  height: auto !important;
  display: flex !important;
  align-content: stretch !important;
  justify-content: flex-start !important;
  align-items: center;
}

.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo
  + .sidenav---navtext---1AE_f {
  width: auto !important;
}

.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo {
  width: 20% !important;
  vertical-align: middle !important;
  opacity: 1 !important;
  height: auto !important;
  margin-right: 0px !important;
}

/* .sidenav---navicon---3gCRo {
    opacity: 1 !important;
} */

.avatar-style {
  display: flex;
  align-items: center;
  line-height: 30px;
}

.admin-avatar {
  width: 50px;
  height: 50px;
  border-radius: 24%;
  margin-right: 10px;
}
.avatar-style .avatar-grettings {
  margin: 16px 0px 0px 0px;
  color: #35a7ff;
}

.avatar-style .avatar-name {
  color: #0059d2;
}

/* Header */

.custom-header-height {
  min-height: 120px;
}

.header-btn-style {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.header-btn-style img {
  height: 24px;
  width: 24px;
}

.submenu-style.sidenav---sidenav-subnavitem---1cD47
  > .sidenav---navitem---9uL5T {
  width: 80%;
  float: right;
}

.submenu-style .sidenav---navitem---9uL5T {
  /* padding: 0 14px 0 20px !important; */
  padding-left: 20px !important;
}

.submenu-style.sidenav---sidenav-subnavitem---1cD47 {
  height: 50px;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL
  .sidenav---sidenav-subnav---1EN61
  > .sidenav---sidenav-subnavitem---1cD47:hover::before {
  opacity: 1 !important;
}

/* .top-area > .sidenav---navitem---9uL5T:hover,.top-area.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T {
  background-color: transparent !important;
}

.top-area.sidenav---selected---1EK3y .sidenav---navitem---9uL5T:after {
  background-color: transparent !important;
} */

.img-btn {
  background-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
  border: 0;
}

.img-btn:hover,
.img-btn:active,
.img-btn:focus,
.img-btn:focus-visible {
  background-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
  border: 0;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}

.btn-groups {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.nav-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #0059d2;
  padding-left: 30px;
}

.modal-btn {
  width: 120px;
  border-radius: 10px;
  border: 1px solid #35a7ff;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  background-color: #ffffff;
  padding: 5px;
}

.modal-btn:hover {
  background-color: transparent !important;
}

.profile-picture-container > img {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.capsule {
  padding: 15px 10px;
  background: rgba(53, 167, 255, 0.05);
  border: 1px solid #35a7ff;
  border-radius: 20px;
}

.cust-switch-btn {
  position: relative;
}

.cust-switch-btn input {
  position: relative;
}

.cust-switch-btn .form-check-input {
  width: 60px;
  height: 25px;
}

.cust-switch-btn .form-check-input:not(:checked) {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
  background-color: #000000 !important;
}

.custom-toaster {
  background: rgba(53, 167, 255, 0.05);
  border: 1px solid #35a7ff;
  backdrop-filter: blur(5px);
  border-radius: 20px;
  width: 303px;
  height: 70px;
}

.custom-toaster .toast-body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px 0;
}

.custom-toaster .toast-body .toast-msg {
  color: #242b43;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #35a7ff80;
}

::-webkit-scrollbar-thumb {
  background: #35a7ff;
  border-radius: 5px;
}

.navbar {
  height: 50px;
  color: #0059d2;
  position: relative;
}

.logo {
  font-weight: bold;
  font-size: 20px;
}

.icon {
  cursor: pointer;
  position: relative;
}

.iconImg {
  width: 40px;
}

.counter {
  background-color: #fff;
  border-radius: 50%;
  padding: 3px;
  position: absolute;
  top: 10px;
  right: 12px;
}

.notifications {
  position: absolute;
  top: 50px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.6);
  border: 1px solid #35a7ff;
  border-radius: 20px;
  box-shadow: 2px 2px 10px rgba(53, 167, 255, 0.5);
  backdrop-filter: blur(7.5px);
  color: black;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 500px;
  overflow: auto;
  padding: 20px 0 20px 20px;
  z-index: 2;
}
.avatar-style .navbar .icons {
  font-size: 20px;
}
.date-format {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: rgba(36, 43, 67, 0.5);
}
.notification {
  padding: 5px;
  font-size: 14px;
}

.nButton {
  width: 80%;
  padding: 5px;
  margin-top: 10px;
  background-color: #000000;
  color: #fff;
}
