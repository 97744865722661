@import "../../../common.scss";
.badge-modal {
  .modal-content {
    background: rgb(255 255 255 / 94%);
    border: 1px solid #35a7ff;
  }
  .searchWrapper {
    border: none;
    border-bottom: 1px solid #35a7ff;
    border-radius: initial;
    padding-left: 0;
    .searchBox {
      width: 100%;
      padding: 5px;
    }
    .searchBox::placeholder {
      color: #242b43;
      opacity: 1;
    }
  }

  .form-control {
    background-color: none;
    border: none;
  }

  textarea::placeholder {
    color: #242b43;
    opacity: 1;
    padding-left: 0;
  }

  & .modal-dialog {
    position: absolute;
    right: 26px;
    min-width: 475px;
    height: 400px;
    top: 153px;
    & .modal-content {
      height: 100%;
      width: 100%;
    }
  }
  .action-btns .btn {
    border-radius: 12px;
  }
}

.custom-modal {
  p {
    color: rgba(36, 43, 67, 1);
    font-size: 16px;
  }
  .modal-dialog {
    position: absolute;
    right: 12px;
    top: 145px;
  }
  .modal-content {
    border-radius: 20px;
    border: 1px solid $brand-color;
    box-shadow: -4px -3px 33px -8px rgba(53, 167, 255, 0.2);
    background-color: transparent;
    backdrop-filter: blur(12px);
  }

  .radio-btn-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    height: 178px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 3px;
      overflow: hidden;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $brand-color;
      outline: 1px solid $brand-color;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: rgba(53, 167, 255, 0.2);
    }

    & .radio-btn {
      input[type="radio"] {
        position: absolute;
        opacity: 0;
        &:checked + .badge-box-items {
          border: 1px solid $brand-color;
        }
      }
    }
    & .badge-box {
      width: 77px;
      border-radius: 12px;
      background-color: rgba(53, 167, 255, 0.2);
      cursor: pointer;
      transition: all 0.3s ease-in;

      & .badge-box-items {
        padding: 10px 5px;
        border-radius: 12px;
        span {
          font-size: 12px;
          display: block;
          padding-top: 5px;
        }

        & .image-badge {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  .searchWrapper {
    border-bottom: 1px solid $brand-color;
    border-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    padding-left: 0;
    & input {
      padding-left: 0;
      &::placeholder {
        color: rgba(36, 43, 67, 1);
      }
    }
  }

  .form-control {
    background-color: transparent;
    &:focus {
      background-color: transparent;
    }
    &::placeholder {
      color: rgba(36, 43, 67, 1);
    }
  }
}

.modal-backdrop.show {
  opacity: 0 !important;
}
