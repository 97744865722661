.metting-detail {
  border-radius: 20px !important;

  .title {
    display: inline-block;
    font-weight: 600;
    font-size: 24px;
    color: #0059d2;
  }

  .meeting-other-info {
    display: inline-block;
    font-weight: 400;
    font-size: 17px;
    color: #0059d2;
  }
  .meeting-other-info-content {
    color: black;
    margin-left: 3px;
  }

  .meeting-img {
    display: inline-block;
    border-radius: 50%;
    width: 35px;
    height: 40px;
    margin: 5px;
  }

  .attendies {
    display: inline-block;
  }

  .metting-header {
    position: relative;

    .add-attendies {
      padding-top: 10px;
      display: inline-grid;
      border: 1px solid #35a7ff;
      backdrop-filter: blur(10px);
      border-radius: 20px;
      margin-left: 20px;

      .ask-agenda {
        display: flex;
        align-items: center;

        p {
          margin: 0;
          padding-left: 10px;
        }
      }
    }

    .add-attendies {
      input {
        border: none;
        margin-bottom: 10px;
        background: transparent;
      }
    }
  }

  .inline-title {
    display: inline-block;
    color: #000000;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  }

  .add-agenda {
    color: #35a7ff;
    font-size: 35px;
    padding-left: 15px;
    cursor: pointer;
  }

  .date-select {
    background: rgba(53, 167, 255, 0.05);
    border: 1px solid #35a7ff;
    border-radius: 7px;

    .form-control {
      border: none;
    }
  }

  .agenda-item {
    display: flex;
    justify-content: space-between;
    background: rgba(53, 167, 255, 0.05);
    border: 1px solid #35a7ff;
    border-radius: 20px;

    .topic {
      span {
        line-height: 25px;
        padding: 15px 30px;
        padding-right: 0;
        font-weight: 400;
        font-size: 24px;
      }
    }

    input {
      border: none;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      background: transparent;
      padding: 22px;
    }

    input::placeholder {
      color: #000000;
      opacity: 1;
    }

    .hr-line {
      color: #35a7ff;
      font-size: 42px;
      font-weight: 100;
    }

    .mod {
      padding: 20px;
    }

    .agenda-timer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: -10px;
      justify-content: center;

      .at {
        background: #fff;
        padding: 3px;
        line-height: 15px;
        font-size: 20px;
        border-radius: 15px;
        box-shadow: 0px 0 10px 2px rgb(53 167 255 / 20%);
        display: flex;
        flex-direction: column;

        .play {
          color: #0f9d58;
        }

        .pause {
          color: #dc3545;
        }

        .time {
          font-size: 10px;
        }
      }
    }
  }

  .rt-denger {
    color: #dc3545;
  }

  .rt-info {
    color: #35a7ff;
  }
  .rt-info-active {
    color: #0059d2;
  }
}

// search option
.addTeam {
  display: flex;

  .add-team-members {
    margin-left: 20px;
    min-width: 330px;

    .css-1s2u09g-control {
      border: 1px solid #35a7ff !important;
      border-radius: 15px;
      background-color: #f5fbff;
    }
  }

  .css-26l3qy-menu {
    background-color: #f5fbff;
    border: 1px solid #35a7ff;
  }
}
.agendaEditor {
  max-width: 350px;
}
