.role-management-container {
  background-color: #ffffff;
  color: #000000;
  border: 0;
  border-radius: 10px;
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cust-class {
  left: 50% !important;
  right: 50% !important;
}

.initial-btn {
  background: rgba(53, 167, 255, 0.05);
  border: 1px dashed #0059d2;
  border-radius: 20px;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: rgba(53, 167, 255, 0.5);
  width: 150px;
  padding: 20px;
  outline: none;
}

.form-modal {
  .title {
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #242b43;
  }
  .input-control {
    width: 340px;
    height: 50px;
    background: rgba(53, 167, 255, 0.05);
    border: 1px solid #35a7ff;
    border-radius: 20px;
    padding: 0 20px;
  }

  .cancel-btn {
    color: #35a7ff !important;
  }

  .save-btn {
    background-color: #35a7ff !important;
    color: #ffffff;
  }

  .modal-btn-group {
    margin-top: 100px;
    display: flex;
    justify-content: space-evenly;
  }
}

.cust-accordion-item {
  padding: 1rem 1.8rem;
  margin-bottom: 20px;
  border-radius: 12px !important;
  outline: none;
  .cust-accordion-header {
    border-radius: 20px;
    outline: none;
    border: 0;
    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &::after {
        display: none;
      }
    }
    button.accordion-button {
      .title,
      .count {
        font-weight: 600;
      }
    }
    button.accordion-button.collapsed {
      .title,
      .count {
        font-weight: 400;
      }
    }
    .title {
      font-size: 24px;
      line-height: 24px;
      color: #0059d2;
      padding-right: 20px;
    }
    .count {
      font-size: 24px;
      line-height: 30px;
      color: #35a7ff;
    }
    .accordion-button {
      padding: 0;
      background-color: transparent !important;
      outline: 0;
      box-shadow: none;
    }
  }
  .cust-accordion-body {
    max-height: 260px;
    overflow: auto;
    padding: 0;

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 1px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #35a7ff;
      border-radius: 1px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #35a7ff;
    }
  }
}

.listing-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #0059d2;
}

.employee-role-listing {
  display: flex;
  background: rgba(53, 167, 255, 0.05);
  border: 1px solid #35a7ff;
  border-radius: 20px;
  padding: 15px 35px;
  margin: 0 5px;
  .profile-picture-container {
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .employee-name {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #242b43;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .actions {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #0059d2;
    vertical-align: middle;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.primary-btn {
  background-color: #35a7ff !important;
  color: #ffffff;
  &:hover {
    background-color: #35a7ff !important;
    color: #ffffff;
  }
}

.secondary-btn {
  color: #35a7ff !important;
}

.info-modal-title {
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;
  color: #242b43;
}

.info-modal-description {
  margin-bottom: 50px;
  font-size: 20px;
  font-weight: normal;
  line-height: 25px;
  color: #242b43;
}

.ctrl {
  display: none !important;
}

.accordion-item:hover {
  .ctrl {
    display: flex !important;
  }
}