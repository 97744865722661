.module-badge {
  .accordion-item {
    margin-bottom: 10px;
    border-radius: 15px !important;

    .accordion-header {
      border-radius: 15px;

      .accordion-button {
        background: #fff;
        border-radius: 15px;
        font-weight: 500;
      }

      .collapsed {
        border-radius: 15px !important;
      }
    }
  }
}

.badge-listing,
.badge-all-heading {
  display: grid;
  grid-template-columns: 12% 28% 15% 40%; //1fr 1.5fr 2fr 2fr 1fr;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
  grid-column-gap: 15px;
}

.badge-listing {
  background: rgba(53, 167, 255, 0.05);
  border: 1px solid #35a7ff;
  border-radius: 20px;
  margin-bottom: 20px;
  padding: 10px;

  p {
    margin: 0;
  }

  .badge-img {
    width: 50px;
  }
}
