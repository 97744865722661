.key-priority-title{
    cursor: pointer;
    padding-top: 10px;
}

.plan-title-issue{
    text-align: center;
    font-family: Roboto;
    float: left;
    margin-top: 5px;
}

.plan-header {
  border-bottom: 1px solid #b2b2b2;
  margin-right: 1px;
}

.tab-style {
  padding: 10px;
  font-weight: 600;
  color: #333;
}

.tab-style:hover {
  font-weight: 800;
  background: #35a7ff;
  color: #fff;
}
.active_vision_top {
  text-align: center;
  color: #1e90ff;
  cursor: pointer;
  border-bottom: 2px solid #1e90ff;
  font-family: Poppins;
  font-weight: 600;
}
.vision-top {
  text-align: center;
  color: #686868;
  cursor: pointer;
  font-family: Poppins;
}

.nav-tabs {
  border-bottom: 1px solid #8eb7fb;

  .nav-link {
    &.active {
      background: #f7fbff;
      border-color: #8eb7fb #8eb7fb #f7fbff;
    }
  }
}

.tab-content {
  background: #fff;
  padding-top: 1px;

  .tab-pane {
    &.active {
      background: transparent;
    }
  }
}
