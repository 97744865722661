.pagination-height {
    height: 100px;

    .arrow-margin {
        margin-top: -5px;
    }

    .enabled-prev-next {
        color: #35A7FF;
        font-weight: 400;
        margin-top: 12px;
    }

    .disabled-prev-next-div {
        width: 70px;
        height: 33px;
        border: none;
        background: white;
        border-radius: 5px;
    }

    .disabled-prev-next {
        color: #808080;
        font-weight: 400;
        margin-top: 12px;
    }
}

.prev-style {
    cursor: pointer;
    user-select: none;
}

.page-item {
    width: 33px;
    height: 33px;
    border-radius: 7px;
    margin-right: 5px;

    .page-item-content {
        text-align: center;
        margin-top: 3px;
        color: #35A7FF;
    }

    .page-dot-content {
        text-align: center;
        color: #35A7FF;
    }
}

.page-item:hover {
    background: rgba(53, 167, 255, 0.2);
    cursor: pointer;
}

.active-page {
    width: 33px;
    height: 33px;
    background: #35A7FF;
    border-radius: 7px;
    margin-right: 5px;

    .page-item-content {
        text-align: center;
        margin-top: 3px;
        color: white;
    }
}

.arrow-style {
    margin-top: 15px;
    margin-right: 6px;
}