.create-meeting {

    border-radius: 20px !important;

    .title {
        display: inline-block;
        font-weight: 600;
        font-size: 24px;
        color: #0059D2;
    }

    .rt-denger {
        color: #DC3545;
        cursor: pointer;
    }

    input,
    textarea {
        background: rgba(53, 167, 255, 0.05);
        border: 1px solid #35A7FF;
        border-radius: 20px;
        padding: 30px;
        outline: none;
    }

    select {
        background: rgba(53, 167, 255, 0.05);
        border: 1px solid #35A7FF;
        border-radius: 20px;
        padding: 15px;
        width: 100%;
        outline: none;
    }

    ::-webkit-input-placeholder,
    :-ms-input-placeholder,
    ::placeholder {
        font-size: 16px;
        color: #000;
    }

    .form-control {
        color: #000 !important;
    }

    .form-control:focus {
        box-shadow: none !important;
    }

    input#recurring,
    input#attendees {
        margin-right: 14px;
    }

    .guest-list {

        padding-bottom: 5px !important;
    
        .g-list-item {

            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;

            .g-name {
                display: flex;
                align-items: center;

                p {
                    margin: 0px 25px;
                }
            }


            img {
                border-radius: 50%;
                width: 41px;
                height: 45px;
            }
        }



    }

    .nf-list-item{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        justify-content: space-evenly;
        margin-bottom: 15px;
        align-content: stretch;
        justify-items: center;

        p{
            margin: 0;
        }
    }
    .attendees-checkbox input{
        width:20px;
        margin-left: 2%;
        height: 20px;
        padding: 0;
    }
    .attendees-label{
        margin-left: 6%;
    }
    .attendees-select{
        height: 60px;
    }


}