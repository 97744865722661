.main-menu {
    margin: auto;
    padding-left: 300px;
    margin-top: 100px;
    font-family: Poppins;
}
.form-data{
    margin: auto;
    padding-left: 100px;
    margin-top: 50px;
    font-family: Poppins;
}

.input-field-employee-servey {
    display: block;
    min-width: 90%;
    margin: 1em;
    padding: 1em;
    width: 35em;
    border-radius: 8px;
    border-style: none;
    border: 1px solid #e4e6e8;
    transition: 0.1s ease;
  }
  
.input-field-employee-servey:hover {
    border-color: #1E48AA;
  }
  
  .submit-button-employee-servey {
    margin: 0.8em;
    padding: 1em;
    border: 1px solid #e4e6e8;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.1s ease-in;
  }
  
 .submit-button-employee-servey:hover {
    background-color: #1E48AA;
    color: white;
  }