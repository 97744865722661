@import "../../../../common";
.edit-chart-wrapper {
  .header {
    color: $brand-dark-text;
    font-size: 24px;
  }

  button[disabled] {
    cursor: not-allowed;
    box-shadow: none;
    opacity: 0.3;
  }
  
}
