/* .reg1{
    width: 200px;
} */
.rowClass {
  width: 300px;
  padding: 2px;
}
.regClass {
  width: 30rem;
  padding: 1rem;
  background-color: #fff;
  margin-top: 3.5rem;
  margin-left: 45rem;
  box-shadow: 5px 5px 10px #ddd;
}
.sign-link:hover {
  cursor: pointer;
}
.bg-img {
  background-image: url("../../images/bg-shape\ \(1\).png");
  background-repeat: no-repeat;
  background-size: 300px;
  position: absolute;
  left: 1rem;
  top: 1rem;
}
.shape-img {
  width: 150px;
  position: absolute;
  left: 73.75rem;
  bottom: -8.4375rem;
}

/* For extremely small screen devices (595px and below) */
@media only screen and (max-width: 375px) {
  .brand-img {
    width: 10rem !important;
  }
  .brand {
    margin-left: 5rem !important;
  }
  .regClass {
    width: 327px;
    margin-top: 1.5rem;
    margin-left: 1rem;
  }
  .bg-img {
    background-image: none;
  }
}

/* Small screen devices (600px and above) */
@media only screen and (min-width: 411px) {
  .brand-img {
    width: 10rem !important;
  }
  .brand {
    margin-left: 5rem !important;
  }
  .regClass {
    width: 327px;
    margin-top: 1.5rem;
    margin-left: 1rem;
  }
  .bg-img {
    background-image: none;
  }
}

/* Medium screen devices (768px and above) */
@media only screen and (min-width: 768px) {
  .responsive-reg {
    margin-top: 2rem;
  }
  .brand-img {
    width: 20rem !important;
  }
  .brand {
    margin-left: 12rem !important;
  }
  .regClass {
    width: 400px;
    margin-top: 2.5rem;
    margin-left: 11rem;
  }
}
.continue {
  padding-left: 11rem;
  padding-top: 3rem;
}
