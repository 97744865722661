.main {
  min-height: 90vh;
}
.active{
  background-color:white;
}

border{
  outline:1px solid black;
}

.border-back{
  background-color:red;
}

.MeetingsContainer{
  margin-top:88px;
  margin-left:255px;
  width: calc(100% - 350px);
}

.meetings-title{
  margin-bottom:25px;
}

.nopadding {
   padding: 0 !important;
   margin: 0 !important;
}


.meetNowBtn{
  width:100%;
  outline:1px solid #2a8ff7;
  color:#2a8ff7;
  &:focus {
    outline:1px solid #2a8ff7;
    color:#2a8ff7;
  }
}

.paddingTop{
  margin-top:15px;
  .subButton{
    outline:1px solid #2a8ff7;
    color:#2a8ff7;
    width:100%;
    &:focus {
      outline:1px solid #2a8ff7;
      color:#2a8ff7;
    }
  }
}

.contributeMessage{
  font-size:14px;
}
.popupActions{
  margin-top:20px;
  .cancelSubmission{
    color:#2a8ff7;
  }
}


.inviteeSelectionList{
  padding:15px;
  margin:0 15px;
  margin-top:20px;
  margin-bottom:20px;
  min-height:200px;
  border:1px solid #dee2e6;

  .memberCheckBox{
    z-index:9;
  }
  .inviteCheckbox{
    position: relative;
    left: 35px;
    top: 6px;
  }
  .inviteeName{
    line-height: 42px;
  }
  .inviteeSelection{
    border:1px solid #dee2e6;
    background-color:#e9f5f8;
    border-radius:5px;
    margin-bottom:1px;
    .inviteeMeetingImage{
      border-radius: 50%;
      width:30px;
      height:auto;
      margin:5px 5px;
    }
  }
}

.required:after {
  content:" *";
  margin-left: 5px;
  color: red;
}

.editorTitle{
  font-weight:bold;
}


.meetingDetails{
  .commentTitle{
    font-weight:bold;
    margin:20px 0;
  }
  .meetingDate{
    font-weight: bold;
    font-size: 14px;
  }

  .addNotes{
    margin:10px 0;
  }

  .ckeditorContainer{
    .ck.ck-editor{
      height:200px;
      overflow:auto;
      width:100%;
    }
  }
  .meetingWidget{
    border:1px solid black;
  }
  .endMeetingBtn{
    border:1px solid black;
  }
  .feedbackCenter{
    text-align:center;

    .feedbackButton{
      background:none;
      border:none;
      .feedbackImage{
        width:30px;
        height:30px;
        margin: 0 8px;
        cursor:pointer;
      }
    }
  }

  .takeAwayContainer{
    margin: 10px 0;
    .takeAwayItem{
      margin: 20px 0;
      .userImage{
        width:40px;
        height:auto;
        border-radius:50%;
      }

      .takeAwayDesc{
        font-size:14px;
      }

      .takeAwayAuthor{
        font-size:12px;
        color:#8C9EA2;
      }

      .takeAwayDate{
        font-size:12px;
        color:#8C9EA2;
      }
    }
  }
  
  .topicContainer{
    margin: 10px 0;
    .topicDiscussionItem{
      margin: 20px 0;

      .topicDiscussioDesc{
        font-size:14px;
      }

      .bulletImage{
        width:20px;
        height:auto;
      }

      .takeAwayAuthor{
        font-size:12px;
        color:#8C9EA2;
      }

      .takeAwayDate{
        font-size:12px;
        color:#8C9EA2;
      }
    }
  }
  
}


.meetingDetails{
  .firstParticipantImage{
    border-radius: 50%;
    width:60px;
    height:auto;
    margin:0 5px 5px 0px;
  }

  .participantImage{
    border-radius: 50%;
    width:60px;
    height:auto;
    margin:5px 5px;
  }
}

.meetingItem{
  border: 1px solid #dee2e6 !important;
  border-radius: 5px;
  margin-bottom: 20px;
  padding:10px 10px;
  a{
    text-decoration:none;
  }
  &:hover {
      background-color:#e9f5f8;
      text-decoration:none;
  }

  .meetingDate{
    color:grey;
    margin:0px 0 20px 0;
    font-size:14px;
  }

  .firstParticipantImage{
    border-radius: 50%;
    width:45px;
    height:auto;
    margin:0 5px 5px 0px;
  }

  .participantImage{
    border-radius: 50%;
    width:35px;
    height:auto;
    margin:5px 5px;
  }

  .meetingTitle{
    color:#000000;
    font-weight:bold;
    font-size:20px;
  }

  .meetingCreator{
    font-size:12px;
  }
}
.session-warning{
  border: 1px solid #DC3545;
  backdrop-filter: blur(5px);
  border-radius: 20px;
  background-color: #f5d0d4;
  padding: 3%;
}


// .accordion {
//   border: 0px;
// }
// .accordion__button{
//   border-radius: 15px;
//   background-color: #fff;
//   color: #0059d2;
// }

// .accordion__button:hover {
//   background-color: #35a7ff;
//   color: #fff;
// }

// .accordion__item + .accordion__item{
//   border-top: 0px;
// }
// .accordion__item{
//   margin-bottom: 20px;
// }

// .accordion__panel {
//   background-color: #fff;
//   margin-bottom: 20px;
// }