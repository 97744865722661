@import "../../common.scss";
.sign-in {
  background: linear-gradient(
    174.76deg,
    rgba(53, 167, 255, 0.05) 0%,
    #35a7ff 100%
  );
  border-radius: 28px;
  .sign-in-box {
    width: 650px;
    padding: 50px 77px;
    background: rgb(255 255 255 / 50%);
    border-radius: 20px;
    backdrop-filter: blur(10px);

    .title {
      font-size: 24px;
      font-weight: 600;
      line-height: 24px;
      color: #0059d2;
      padding-bottom: 20px;
    }
    .form-control {
      background: transparent;
      border: none;
      color: #000;
      font-size: 20px;
      font-weight: 400;
      &:focus {
        color: #333;
        box-shadow: none;
      }
    }
    .desc {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
    .input-f {
      display: flex;
      background: rgba(53, 167, 255, 0.05);
      border: 1px solid #fff;
      border-radius: 20px;
      padding: 10px 20px;
      align-items: center;

      .icon-color {
        color: #0059d2;
      }
      &.org-field {
        background-color: $brand-color-light;
        font-weight: 500;
      }
    }
    .log-btn {
      background: #35a7ff;
      color: #fff;
      width: 100%;
      padding: 15px;
      border-radius: 20px;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;

      &:hover {
        background: #0059d2;
      }
    }
    input.checkbox {
      width: 18px;
      height: 24px;
      filter: opacity(0.5);
    }
  }
  .sign-in-bottom {
    a {
      color: #0059d2;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
