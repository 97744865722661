// .process-documentation {
//     h1 {
//       background: #edf3ff;
//     }
//     .card {
//       background-color: #f5f9ff;
//       border: none;
//       border-radius: 10px;

//       .card-body {
//         .card-text {
//           text-overflow: ellipsis;
//           overflow: hidden;
//           height: 124px;
//         }
//         .group {
//           background: rgba(88, 135, 255, 0.1);
//           width: max-content;
//           padding: 5px 15px;
//           text-align: center;
//           color: #5887ff;
//           font-size: 14px;
//           font-weight: 400;
//           border-radius: 8px;

//           .name {
//             font-weight: 500;
//           }
//         }

//         .btn {
//           width: 90px;
//         }
//       }
//     }
//   }

// .custom-modal {
//   min-width: 80%;

//   .modal-content {
//     padding: 10px;
//     max-height: 100vh;
//     overflow: auto;
//     min-height: 90vh;

//     .modal-header button.btn-close {
//       border: 1px solid #333;

//       &::before {
//         content: "X";
//         color: #333;
//         font-weight: 400;
//       }
//     }
//   }
// }

.rt-tag {
  padding: 5px 15px;
  color: #35A7FF;
  background: rgba(53, 167, 255, 0.2);
  border-radius: 7px;
  font-size: 14px;
}

.rt-text-primary {
  color: #0059D2;
}

.rt-text-secondary {
  color: #35A7FF;
}

// above common css


.process-documentation {
  background: #fff;
  border-radius: 20px;

  .card {
    background: rgba(53, 167, 255, 0.05);
    border: 1px solid #35A7FF;
    border-radius: 20px;
    margin-bottom: 20px !important;
  }

  .rt-doc,
  .rt-doc p {
    max-height: 80px;
    overflow: hidden;
  }

  .rt-pagination {
    display: flex;
    align-content: center;
    justify-content: center;

    a{
      color: #0059D2;
    }
  }
}

.modal-backdrop {
  opacity: 0.5 !important;
}

.rt-modal {
  .modal-content {
    background: rgba(255, 255, 255, 0.99);
    border: 1px solid #35a7ff;
    border-radius: 20px;
  }
}